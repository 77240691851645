import React from "react"

const NotFound = () => (
  <div className="NotFound">
    <h1 style={{ color: "#999999" }}>404</h1>
    <h2 style={{ color: "#999999" }}>Not Found</h2>
  </div>
)

export default NotFound
